import getProductsServerSide from '@/utils/pagesServerSide/getProductsServerSide';
import api from '@/api/products';
import createUUID from '@/utils/createUUID';
import isMicrosite from '@/utils/isMicrosite';

export { default } from '@/pages/productos';

export async function getServerSideProps(ctx) {
  const { slugMicrosite } = ctx.params;

  // Es una landing
  const landingEventID = createUUID();
  const isLanding = await api.validateSlug(
    'vaypol',
    landingEventID,
    slugMicrosite
  );

  if (isLanding.landing) {
    return getProductsServerSide(ctx, '', slugMicrosite, {
      landingEventID,
      ...isLanding
    });
  }
  const isSlugMicrosite = await isMicrosite(slugMicrosite);
  if (!isSlugMicrosite) {
    ctx.params.slugs = [...(ctx.params?.slugs || []), slugMicrosite];
    delete ctx.params.slugMicrosite;
  }
  return getProductsServerSide(ctx);
}
